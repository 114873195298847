import './App.css';
import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import MyLayout from './components/MyLayout';
import Store from '@mui/icons-material/Store';
import UserIcon from '@mui/icons-material/People';
import Payment from '@mui/icons-material/Payment';
import ListAlt from '@mui/icons-material/ListAlt';
import EventMonitor from './components/EventMonitor';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomLoginPage from './components/CustomLoginPage';
import { config, optionsLazyLoad } from './FIREBASE_CONFIG'
import ShoppingBasket from '@mui/icons-material/ShoppingBasket';
import { FirebaseDataProvider, FirebaseAuthProvider, } from 'react-admin-firebase';

import Dashboard from './screens/Dashboard';
import { OrderList } from './screens/Orders';
import { PaymentList } from './screens/Payments';
import { UserList, UserShow } from './screens/Users';
import { NewslettersList } from './screens/Newsletters';
import { StoresReportsList } from './screens/StoresReports';
import { RegisteredShopsList } from './screens/RegisteredShops';
import { AdHeaderEdit, AdHeaderList } from './screens/AdHeader';
import { StoreList, StoreShow, StoreEdit } from './screens/Stores';
import { ProductEdit, ProductList, ProductShow } from './screens/Products';
import { CampaignCreate, CampaignEdit, CampaignList } from './screens/Campaigns';


const dataProvider = FirebaseDataProvider(config, optionsLazyLoad);
const authProvider = FirebaseAuthProvider(config, optionsLazyLoad);

// Use this just like the normal auth provider
const myAuthProvider = {
  // Copy all authprovider functionality
  ...authProvider,
  // Wrap the login and check for custom claims
  login: async (params: {}) => {
    const user = await authProvider.login(params);
    // getPermissions is how when get the custom claims for the logged in user
    const claims = await authProvider.getPermissions({});
    if (claims?.admin) {
      return user;
    }
    // Make sure user is logged out, if not an admin
    await authProvider.logout({})
    throw new Error("Login error, invalid permissions");
  },
};

function App() {
  return <>
    <Admin
      loginPage={CustomLoginPage}
      dataProvider={dataProvider}
      authProvider={myAuthProvider}
      dashboard={Dashboard}
      layout={MyLayout}
      darkTheme={{ palette: { mode: 'dark' } }}
    >
      <Resource
        name="orders"
        list={OrderList}
        icon={ShoppingBasket}
      />
      <Resource
        name="payments"
        list={PaymentList}
        icon={Payment}
      />
      <Resource
        name="stores"
        list={StoreList}
        icon={Store}
        show={StoreShow}
        edit={StoreEdit}
      />
      <Resource
        name="products"
        list={ProductList}
        icon={ListAlt}
        show={ProductShow}
        edit={ProductEdit}
      />
      <Resource
        name="users"
        list={UserList}
        icon={UserIcon}
        show={UserShow}
      />
      <Resource
        name="siteSettings"
        list={AdHeaderList}
        icon={SettingsIcon}
        edit={AdHeaderEdit}
        options={{ label: 'Site Settings' }}
      />
      <Resource
        name="campaigns"
        list={CampaignList}
        icon={UserIcon}
        create={CampaignCreate}
        edit={CampaignEdit}
      />
      <Resource
        name="registeredShops"
        list={RegisteredShopsList}
        //icon={UserIcon}
        options={{ label: 'Registered Shops' }}
      />
      <Resource
        name="newsletters"
        list={NewslettersList}
        icon={UserIcon}
        options={{ label: 'News Letters' }}
      />
      <Resource
        icon={UserIcon}
        name="storesReports"
        list={StoresReportsList}
        options={{ label: 'Stores Reports' }}
      />
    </Admin>
    <EventMonitor />
  </>
}

export default App;
